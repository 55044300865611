body {
  background-color: #0f0f0f;
  color: #fff;
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.content {
  /* width: 100%; */
  margin-top: 60px; /* Adjust this to account for the navbar height */
}


.pagination-container {
  display: flex;
  justify-content: center; /* Centers the pagination */
  margin-top: 20px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #e9ecef;
  text-decoration: none;
  cursor: pointer;
}

.page-link:hover {
  background-color: #d78e2f;
}

.active .page-link {
  background-color: #d78e2f;
  color: white;
  border: 1px solid #d78e2f;
}
